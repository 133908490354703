export const useGetInternalLinkFromUrl = (link) => {
  try {
    link = new URL(link).pathname
  } catch (error) {
    if (!process.server && window?.location?.search?.includes('_storyblok')) {
      alert(
        `Dieser auf der Seite angegebene Link aus der Seite ist ungültig und sollte gefixed werden: ${link}`
      )
    }
  }
  return link
}

// eslint-disable-next-line complexity
export const useCalcedLink = (item) => {
  if (!item.link || !item.link.cached_url) {
    return null
  }
  let link = item.link.cached_url
  if (item.link.linktype === 'story') {
    link = useGetInternalLinkFromUrl(link)
  }
  if (item.link.linktype === 'asset') {
    link = (link || '').replace('//a.storyblok.com', '//assets.pflege.de')
  }
  if (item.link.anchor) {
    link += `#${item.link.anchor}`
  }
  return link
}
